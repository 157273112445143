
import { Options, Vue } from "vue-class-component";

// Proxy
import * as proxy from "@/configuration/proxy";

// Components
import {
  ElHeader,
  ElMain,
  ElContainer,
  ElRow,
  ElCol,
} from "element-plus";
import Navigation from "@/components/Navigation.vue";

@Options({
  components: {
    ElHeader,
    ElMain,
    ElContainer,
    ElRow,
    ElCol,
    Navigation,
  }
})
export default class PreviewSnippetIndexVue extends Vue {
  config = {};
  formData: { environment: string; widget: string; type: string; device: string, previewType: string, customDesktopSize: string, backgroundColor: string, backgroundColorType: string } = {
    environment: "",
    type: "",
    device: "",
    widget: "",
    previewType: "",
    customDesktopSize: "100",
    backgroundColor: "transparent",
    backgroundColorType: "Light"
  };

  created(): void {
    this.config = JSON.parse((this.$route.query.config as string).replaceAll("~", "#"));
    this.formData = JSON.parse(this.$route.query.formData as string);
  }

  mounted(): void {
    switch (this.formData.widget) {
      case "apofinder-v1":
        proxy.initializeApofinderV1(this.config);
        break;
      case "apofinder-v2":
        proxy.initializeApofinderV2(this.config);
        break;
      case "apofinder-v2-for-manufacturer-landing-page":
        proxy.initializeApofinderV2(this.config);
        break;
      case "manufacturer":
        proxy.initializeManufacturer(this.config);
        break;
      case "manufacturer-shop-v2":
      case "manufacturer-shop-v2-deep-link-default":
      case "manufacturer-shop-v2-deep-link-custom":
        proxy.initializeManufacturerShop(this.config);
        break;
      case "net-doctor":
        proxy.initializeNetDoctor(this.config);
        break;
      case "net-doctor-indicator":
        proxy.initializeNetDoctorIndicator(this.config);
        break;
      case "net-doctor-with-pharmacy":
        proxy.initializeNetDoctorWithPharmacy(this.config);
        break;
      case "product-search":
        proxy.initializeProductSearch(this.config);
        break;
      case "product-with-category":
        proxy.initializeProductWithCategory(this.config);
        break;
      case "shopping":
        proxy.initializeShopping(this.config);
        break;
      case "wirkenstoff":
        proxy.initializeWirkenstoff(this.config);
        break;
    }
  }

  getCustomDesktopWidth(): string {
    if (this.formData.previewType === 'custom-desktop-width') {
      return this.formData.customDesktopSize === '100' ? '100%' : this.formData.customDesktopSize + 'px'
    }

    return '100%'
  }
}
