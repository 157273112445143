import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "section-default" }
const _hoisted_3 = { class: "settings" }
const _hoisted_4 = { class: "form" }
const _hoisted_5 = { class: "editor-style" }
const _hoisted_6 = { class: "editor-style" }
const _hoisted_7 = { class: "editor-style" }
const _hoisted_8 = { class: "action" }
const _hoisted_9 = { class: "section-default" }
const _hoisted_10 = { class: "preview" }
const _hoisted_11 = { class: "screen" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_color_picker = _resolveComponent("el-color-picker")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_main = _resolveComponent("el-main")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      class: _normalizeClass(["notification", { 'animateOpen': _ctx.showNotification }])
    }, "Copied to clipboard", 2),
    _createVNode(_component_el_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_Navigation)
      ]),
      _: 1
    }),
    _createVNode(_component_el_main, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_el_row, {
              gutter: 32,
              type: "flex"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, {
                  xs: 24,
                  sm: 24,
                  md: 6,
                  lg: 6,
                  xl: 6
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_el_form, {
                        model: _ctx.formData,
                        rules: _ctx.formDataRules,
                        ref: "formComponent",
                        onSubmit: _withModifiers(_ctx.validateForm, ["prevent"])
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_form_item, {
                            label: "Environment",
                            prop: "environment"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_select, {
                                modelValue: _ctx.formData.environment,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.environment) = $event)),
                                placeholder: "Select"
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionsEnvironment, (item) => {
                                    return (_openBlock(), _createBlock(_component_el_option, {
                                      key: item.value,
                                      label: item.label,
                                      value: item.value
                                    }, null, 8, ["label", "value"]))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["modelValue"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_el_form_item, {
                            label: "Type",
                            prop: "type"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_select, {
                                modelValue: _ctx.formData.type,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.type) = $event)),
                                placeholder: "Select",
                                onChange: _ctx.resetWidgetList
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionsType, (item) => {
                                    return (_openBlock(), _createBlock(_component_el_option, {
                                      key: item.value,
                                      label: item.label,
                                      value: item.value
                                    }, null, 8, ["label", "value"]))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["modelValue", "onChange"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_el_form_item, {
                            label: "Device",
                            prop: "device"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_select, {
                                modelValue: _ctx.formData.device,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.device) = $event)),
                                placeholder: "Select"
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionsDevice, (item) => {
                                    return (_openBlock(), _createBlock(_component_el_option, {
                                      key: item.value,
                                      label: item.label,
                                      value: item.value
                                    }, null, 8, ["label", "value"]))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["modelValue"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_el_form_item, {
                            label: "Widgets",
                            prop: "widget"
                          }, {
                            default: _withCtx(() => [
                              (_ctx.formData.type === 'iframe')
                                ? (_openBlock(), _createBlock(_component_el_select, {
                                    key: 0,
                                    modelValue: _ctx.formData.widget,
                                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.widget) = $event)),
                                    placeholder: "Select"
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionsIframeWidgets, (item) => {
                                        return (_openBlock(), _createBlock(_component_el_option, {
                                          key: item.value,
                                          label: item.label,
                                          value: item.value
                                        }, null, 8, ["label", "value"]))
                                      }), 128))
                                    ]),
                                    _: 1
                                  }, 8, ["modelValue"]))
                                : _createCommentVNode("", true),
                              (_ctx.formData.type === 'snippet')
                                ? (_openBlock(), _createBlock(_component_el_select, {
                                    key: 1,
                                    modelValue: _ctx.formData.widget,
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.widget) = $event)),
                                    placeholder: "Select"
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionsSnippetWidgets, (item) => {
                                        return (_openBlock(), _createBlock(_component_el_option, {
                                          key: item.value,
                                          label: item.label,
                                          value: item.value
                                        }, null, 8, ["label", "value"]))
                                      }), 128))
                                    ]),
                                    _: 1
                                  }, 8, ["modelValue"]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_el_form_item, {
                            label: "Preview Type",
                            prop: "previewType"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_select, {
                                modelValue: _ctx.formData.previewType,
                                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formData.previewType) = $event)),
                                placeholder: "Select"
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionsPreviewType, (item) => {
                                    return (_openBlock(), _createBlock(_component_el_option, {
                                      key: item.value,
                                      label: item.label,
                                      value: item.value
                                    }, null, 8, ["label", "value"]))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["modelValue"])
                            ]),
                            _: 1
                          }),
                          (_ctx.formData.previewType === 'custom-desktop-width')
                            ? (_openBlock(), _createBlock(_component_el_form_item, {
                                key: 0,
                                label: "Custom desktop size",
                                prop: "customDesktopSize"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _ctx.formData.customDesktopSize,
                                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formData.customDesktopSize) = $event)),
                                    placeholder: "Custom desktop size"
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          _createVNode(_component_el_form_item, {
                            label: "Background color",
                            prop: "backgroundColorType"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_select, {
                                modelValue: _ctx.formData.backgroundColorType,
                                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formData.backgroundColorType) = $event)),
                                placeholder: "Select"
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.backgroundColors, (item) => {
                                    return (_openBlock(), _createBlock(_component_el_option, {
                                      key: item.value,
                                      label: item.label,
                                      value: item.value
                                    }, null, 8, ["label", "value"]))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["modelValue"])
                            ]),
                            _: 1
                          }),
                          (_ctx.formData.backgroundColorType === 'custom')
                            ? (_openBlock(), _createBlock(_component_el_form_item, {
                                key: 1,
                                label: "Custom background color",
                                prop: "backgroundColor"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_color_picker, {
                                    modelValue: _ctx.formData.backgroundColor,
                                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formData.backgroundColor) = $event)),
                                    "color-format": "hex",
                                    placeholder: "Custom background color"
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          _createVNode(_component_el_form_item, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_button, {
                                type: "primary",
                                class: "btn-primary btn-block",
                                "native-type": "submit"
                              }, {
                                default: _withCtx(() => _cache[12] || (_cache[12] = [
                                  _createTextVNode("Confirm ")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["model", "rules", "onSubmit"])
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, {
                  xs: 24,
                  sm: 24,
                  md: 18,
                  lg: 18,
                  xl: 18
                }, {
                  default: _withCtx(() => [
                    _cache[19] || (_cache[19] = _createElementVNode("div", { class: "label" }, "1. HTML:", -1)),
                    _createElementVNode("div", _hoisted_5, [
                      _cache[13] || (_cache[13] = _createElementVNode("div", {
                        class: "editor html-style html-editor language-js",
                        "data-gramm": "false"
                      }, null, -1)),
                      _createElementVNode("button", {
                        type: "button",
                        onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.copyToClipboard($event.target)))
                      }, "Copy")
                    ]),
                    _cache[20] || (_cache[20] = _createElementVNode("div", { class: "label" }, "2. Configuration:", -1)),
                    _createElementVNode("div", _hoisted_6, [
                      _cache[14] || (_cache[14] = _createElementVNode("div", {
                        class: "editor config-editor language-js",
                        "data-gramm": "false"
                      }, null, -1)),
                      _createElementVNode("button", {
                        type: "button",
                        onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.copyToClipboard($event.target)))
                      }, "Copy")
                    ]),
                    _cache[21] || (_cache[21] = _createElementVNode("div", { class: "label" }, "3. Script:", -1)),
                    _createElementVNode("div", _hoisted_7, [
                      _cache[15] || (_cache[15] = _createElementVNode("div", {
                        class: "editor script-style script-editor language-js",
                        "data-gramm": "false"
                      }, null, -1)),
                      _createElementVNode("button", {
                        type: "button",
                        onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.copyToClipboard($event.target)))
                      }, "Copy")
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_el_button, {
                        type: "primary",
                        class: "btn-light",
                        onClick: _ctx.setPreview
                      }, {
                        default: _withCtx(() => _cache[16] || (_cache[16] = [
                          _createTextVNode("Apply ")
                        ])),
                        _: 1
                      }, 8, ["onClick"]),
                      _createVNode(_component_el_button, {
                        type: "primary",
                        class: "btn-light",
                        onClick: _ctx.setWidgetConfiguration
                      }, {
                        default: _withCtx(() => _cache[17] || (_cache[17] = [
                          _createTextVNode("Reset ")
                        ])),
                        _: 1
                      }, 8, ["onClick"]),
                      _createVNode(_component_el_button, {
                        type: "primary",
                        class: "btn-light",
                        onClick: _ctx.resetCodeJar
                      }, {
                        default: _withCtx(() => _cache[18] || (_cache[18] = [
                          _createTextVNode("Clear ")
                        ])),
                        _: 1
                      }, 8, ["onClick"])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", {
              class: _normalizeClass(["marvel-device", { 'iphone8plus black': _ctx.formData.device === 'mobile', 'ipad black': _ctx.formData.device === 'tablet', 'macbook': _ctx.formData.device === 'desktop' }])
            }, [
              _cache[22] || (_cache[22] = _createElementVNode("div", { class: "top-bar" }, null, -1)),
              _cache[23] || (_cache[23] = _createElementVNode("div", { class: "sleep" }, null, -1)),
              _cache[24] || (_cache[24] = _createElementVNode("div", { class: "volume" }, null, -1)),
              _cache[25] || (_cache[25] = _createElementVNode("div", { class: "camera" }, null, -1)),
              _cache[26] || (_cache[26] = _createElementVNode("div", { class: "sensor" }, null, -1)),
              _cache[27] || (_cache[27] = _createElementVNode("div", { class: "speaker" }, null, -1)),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", {
                  class: _normalizeClass(["preview-content", { 'macOS': _ctx.isMacbook, 'firefox': _ctx.isFirefox }])
                }, null, 2)
              ]),
              _cache[28] || (_cache[28] = _createElementVNode("div", { class: "home" }, null, -1)),
              _cache[29] || (_cache[29] = _createElementVNode("div", { class: "bottom-bar" }, null, -1))
            ], 2)
          ])
        ])
      ]),
      _: 1
    })
  ]))
}