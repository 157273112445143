const getApofinder = (environment: { urlPrefix: string }): string => {
  return JSON.stringify({
    trackingCode: "",
    widgetName: "Apofinder-Widget",
    iframeContainerId: "IframeContainer",
    urlPrefix: `${environment.urlPrefix}/`
  }, null, 2)
};

const getCategory = (environment: { urlPrefix: string }): string => {
  return JSON.stringify({
    PZNs: ["00679368","05461711","00001146","14060537"],
    headline: "",
    show_default_headline: "0",
    categoryid: "2",
    openlink: "1",
    showlegal: "1",
    categoryButtonText: "2",
    widgetName: "Category-Widget",
    iframeContainerId: "IframeContainer",
    urlPrefix: `${environment.urlPrefix}/`
  }, null, 2);
};

const getCustomProducts = (environment: { urlPrefix: string }): string => {
  return JSON.stringify({
    primaryColor: "32788F",
    secondaryColor: "003F54",
    widgetKey: "19b607178c64a202c7f48d0598a8c1a522dac5c6fa46392dfa89bfef5eccae8e",
    products: ["10789112,12550409,14384903"],
    pharmacyId: "2163",
    trackingCode: "",
    widgetName: "Custom-Products",
    iframeContainerId: "IframeContainer",
    urlPrefix: `${environment.urlPrefix}/`
  }, null, 2);
};

const getIndicatorProducts = (environment: { urlPrefix: string }): string => {
  return JSON.stringify({
    widgetKey: "638fcb0eef5d34f97b95cd0489be436358360515d042d4ea96a04cd4a983b637",
    primaryColor: "32788F",
    secondaryColor: "003F54",
    pharmacyId: "2163",
    trackingCode: "",
    widgetName: "Indicator-Products",
    iframeContainerId: "IframeContainer",
    urlPrefix: `${environment.urlPrefix}/`
  }, null, 2)
};

const getNetDoktorOne = (environment: { urlPrefix: string }): string => {
  return JSON.stringify({
    style: "1",
    wirkstoffe: "Test work",
    trackingCode: "",
    widgetName: "Net-Doktor-One",
    iframeContainerId: "IframeContainer",
    urlPrefix: `${environment.urlPrefix}/`
  }, null, 2)
};

const getNetDoktorTwo = (environment: { urlPrefix: string }): string => {
  return JSON.stringify({
    style: "2",
    wirkstoffe: "Test work",
    trackingCode: "",
    widgetName: "Net-Doktor-Two",
    iframeContainerId: "IframeContainer",
    urlPrefix: `${environment.urlPrefix}/`
  }, null, 2)
};

const getManufacturerOne = (environment: { urlPrefix: string }): string => {
  return JSON.stringify({
    products: "0714573,04704198,08907113",
    trackingCode: "",
    widgetName: "Manufacturer-One",
    iframeContainerId: "IframeContainer",
    urlPrefix: `${environment.urlPrefix}/`
  }, null, 2)
};

const getManufacturerTwo = (environment: { urlPrefix: string }): string => {
  return JSON.stringify({
    products: "00714573,04704198,08907113",
    type: "2",
    trackingCode: "",
    widgetName: "Manufacturer-Two",
    iframeContainerId: "IframeContainer",
    urlPrefix: `${environment.urlPrefix}/`
  }, null, 2)
};

const getProductsWithCategories = (environment: { urlPrefix: string }): string => {
  return JSON.stringify({
    pharmacyId: "2163",
    apodeId: "",
    fontColor: "32788F",
    primaryColor: "003F54",
    secondaryColor: "003F54",
    trackingCode: "",
    widgetName: "Products-With-Categories",
    iframeContainerId: "IframeContainer",
    urlPrefix: `${environment.urlPrefix}/`
  }, null, 2)
};

const getTopProducts = (environment: { urlPrefix: string }): string => {
  return JSON.stringify({
    pharmacyId: "2163",
    primaryColor: "32788F",
    secondaryColor: "003F54",
    trackingCode: "",
    widgetName: "Top-Products",
    iframeContainerId: "IframeContainer",
    urlPrefix: `${environment.urlPrefix}/`
  }, null, 2)
};

const getTopMarkenshops = (environment: { urlPrefix: string }): string => {
  return JSON.stringify({
    primaryColor: "32788F",
    secondaryColor: "003F54",
    widgetKey: "252e626958bb24b5567564c960b6e0bda66a2d2e8bfccf300d978b3c33c5abce",
    trackingCode: "",
    widgetName: "Top-Markenshops",
    iframeContainerId: "IframeContainer",
    urlPrefix: `${environment.urlPrefix}/`
  }, null, 2)
};

export {
  getApofinder,
  getCategory,
  getCustomProducts,
  getIndicatorProducts,
  getNetDoktorOne,
  getNetDoktorTwo,
  getManufacturerOne,
  getManufacturerTwo,
  getProductsWithCategories,
  getTopMarkenshops,
  getTopProducts
}