
import { Options, Vue } from "vue-class-component";

// Config
import * as iframeConfiguration from "@/configuration/iframe";
import * as snippetConfiguration from "@/configuration/snippet";
import * as scriptContentConfiguration from "@/configuration/snippet/script";
import * as htmlContentConfiguration from "@/configuration/snippet/html";

// Vendors
import { CodeJar } from "codejar";
import Prism from "prismjs";

// Components
import {
  ElHeader,
  ElMain,
  ElRow,
  ElCol,
  ElForm,
  ElFormItem,
  ElSelect,
  ElOption,
  ElButton,
  ElInput,
  ElColorPicker,
} from "element-plus";
import Navigation from "@/components/Navigation.vue";

@Options({
  components: {
    ElHeader,
    ElMain,
    ElRow,
    ElCol,
    ElForm,
    ElFormItem,
    ElSelect,
    ElOption,
    ElButton,
    ElInput,
    Navigation,
    ElColorPicker,
  },
})
export default class HomeIndex extends Vue {
  optionsEnvironment = [
    {
      label: "Localhost",
      value: "localhost",
    },
    {
      label: "Development",
      value: "development",
    },
    {
      label: "Qa",
      value: "qa",
    },
    {
      label: "Production",
      value: "production",
    },
  ];
  optionsType = [
    {
      label: "Iframe",
      value: "iframe",
    },
    {
      label: "Snippet",
      value: "snippet",
    },
  ];
  optionsDevice = [
    {
      label: "Desktop",
      value: "desktop",
    },
    {
      label: "Tablet",
      value: "tablet",
    },
    {
      label: "Mobile",
      value: "mobile",
    },
  ];
  optionsIframeWidgets = [
    {
      label: "Apofinder",
      value: "apofinder",
    },
    {
      label: "Category",
      value: "category",
    },
    {
      label: "Custom Products",
      value: "custom-products",
    },
    {
      label: "Indicator Products",
      value: "indicator-products",
    },
    {
      label: "NetDoktor One",
      value: "net-doktor-one",
    },
    {
      label: "NetDoktor Two",
      value: "net-doktor-two",
    },
    {
      label: "Manufacturer One",
      value: "manufacturer-one",
    },
    {
      label: "Manufacturer Two",
      value: "manufacturer-two",
    },
    {
      label: "Products with Categories",
      value: "products-with-categories",
    },
    {
      label: "Top Products",
      value: "top-products",
    },
    {
      label: "Top Markenshops",
      value: "top-markenshops",
    },
  ];
  optionsSnippetWidgets = [
    {
      label: "Apofinder V1",
      value: "apofinder-v1",
    },
    {
      label: "Apofinder V2",
      value: "apofinder-v2",
    },
    {
      label: "Apofinder V2 for manufacturer landing page",
      value: "apofinder-v2-for-manufacturer-landing-page",
    },
    {
      label: "Manufacturer Shop V2",
      value: "manufacturer-shop-v2",
    },
    {
      label: "Manufacturer Shop V2 Deep Link Default",
      value: "manufacturer-shop-v2-deep-link-default",
    },
    {
      label: "Net Doctor",
      value: "net-doctor",
    },
    {
      label: "Net Doctor Indicator",
      value: "net-doctor-indicator",
    },
    {
      label: "Net Doctor With Pharmacy",
      value: "net-doctor-with-pharmacy",
    },
    {
      label: "Product Search",
      value: "product-search",
    },
    {
      label: "Product with Category",
      value: "product-with-category",
    },
  ];
  optionsPreviewType = [
    {
      label: "Article with sidebar",
      value: "article-with-sidebar",
    },
    {
      label: "Full width article",
      value: "full-width-article",
    },
    {
      label: "Custom desktop width",
      value: "custom-desktop-width",
    },
  ];
  backgroundColors = [
    {
      label: "Default",
      value: "default",
    },
    {
      label: "Custom",
      value: "custom",
    }
  ]

  formData: { environment: string, type: string, device: string, widget: string, previewType: string, customDesktopSize: string, backgroundColorType: string, backgroundColor: string } = {
    environment: "localhost",
    type: "iframe",
    device: "desktop",
    widget: "apofinder",
    previewType: "article-with-sidebar",
    customDesktopSize: "608",
    backgroundColorType: 'default',
    backgroundColor: "transparent",
  };
  formDataRules = {
    environment: [
      {
        required: true,
        message: "Please select option",
        trigger: "blur",
      },
    ],
    type: [
      {
        required: true,
        message: "Please select option",
        trigger: "blur",
      },
    ],
    device: [
      {
        required: true,
        message: "Please select option",
        trigger: "blur",
      },
    ],
    widget: [
      {
        required: true,
        message: "Please select option",
        trigger: "blur",
      },
    ],
    previewType: [
      {
        required: true,
        message: "Please select option",
        trigger: "blur",
      },
    ],
  };

  widgetIframeUrls = {
    localhost: {
      urlPrefix: "https://ia.local",
    },
    development: {
      urlPrefix: "https://dev-widgets.ihreapotheken.de"
    },
    qa: {
      urlPrefix: "https://qa.ihreapotheken.de"
    },
    production: {
      urlPrefix: "https://ihreapotheken.de"
    },
  }
  widgetSnippetsUrls = {
    localhost: {
      widgetBase: "https://ia.local",
      apiBase: "https://ia.local",
      apiBaseElastic: "https://api-dev.ia.de",
    },
    development: {
      widgetBase: "https://dev-widgets.ihreapotheken.de",
      apiBase: "https://dev.ihreapotheken.de",
      apiBaseElastic: "https://api-dev.ia.de",
    },
    qa: {
      widgetBase: "https://qa.ihreapotheken.de",
      apiBase: "https://qa.ihreapotheken.de",
      apiBaseElastic: "https://api-qa.ia.de",
    },
    production: {
      widgetBase: "https://ihreapotheken.de",
      apiBase: "https://ihreapotheken.de",
      apiBaseElastic: "https://api.ia.de",
    },
  }

  isMacbook: boolean | false = false;

  isFirefox: boolean | false = false;

  showNotification: boolean | false = false;

  notificationTimeout = null;

  codeJar;

  codeJarForScripts;

  codeJarForHtml;

  declare $refs: {
    formComponent: HTMLFormElement;
  };

  validateForm(): void {
    this.$refs.formComponent.validate((response) => {
      if (response) {
        this.setWidgetConfiguration();
        this.setPreview();
      }
    });
  }

  setWidgetConfiguration(): void {
    switch (this.formData.type) {
      case "iframe":
        this.setIframeConfiguration();
        break;
      case "snippet":
        this.setSnippetConfiguration();
        break;
    }
  }

  setIframeConfiguration(): void {
    this.resetCodeJar();
    switch (this.formData.widget) {
      case "apofinder":
        this.setCodeJar(iframeConfiguration.getApofinder(this.widgetIframeUrls[this.formData.environment]));
        break;
      case "category":
        this.setCodeJar(iframeConfiguration.getCategory(this.widgetIframeUrls[this.formData.environment]));
        break;
      case "custom-products":
        this.setCodeJar(iframeConfiguration.getCustomProducts(this.widgetIframeUrls[this.formData.environment]));
        break;
      case "indicator-products":
        this.setCodeJar(iframeConfiguration.getIndicatorProducts(this.widgetIframeUrls[this.formData.environment]));
        break;
      case "net-doktor-one":
        this.setCodeJar(iframeConfiguration.getNetDoktorOne(this.widgetIframeUrls[this.formData.environment]));
        break;
      case "net-doktor-two":
        this.setCodeJar(iframeConfiguration.getNetDoktorTwo(this.widgetIframeUrls[this.formData.environment]));
        break;
      case "net-doctor-with-pharmacy":
        this.setCodeJar(snippetConfiguration.getNetDoctorWithPharmacy(this.widgetSnippetsUrls[this.formData.environment]));
        break;
      case "manufacturer-one":
        this.setCodeJar(iframeConfiguration.getManufacturerOne(this.widgetIframeUrls[this.formData.environment]));
        break;
      case "manufacturer-two":
        this.setCodeJar(iframeConfiguration.getManufacturerTwo(this.widgetIframeUrls[this.formData.environment]));
        break;
      case "products-with-categories":
        this.setCodeJar(iframeConfiguration.getProductsWithCategories(this.widgetIframeUrls[this.formData.environment]));
        break;
      case "top-products":
        this.setCodeJar(iframeConfiguration.getTopProducts(this.widgetIframeUrls[this.formData.environment]));
        break;
      case "top-markenshops":
        this.setCodeJar(iframeConfiguration.getTopMarkenshops(this.widgetIframeUrls[this.formData.environment]));
        break;
      default:
        this.setCodeJar("");
    }
  }

  setSnippetConfiguration(): void {
    this.resetCodeJar();
    switch (this.formData.widget) {
      case "apofinder-v1":
        this.setCodeJar(snippetConfiguration.getApofinderV1(this.widgetSnippetsUrls[this.formData.environment]));
        this.setScriptContent(scriptContentConfiguration.getApofinderV1Script(this.widgetSnippetsUrls[this.formData.environment].widgetBase));
        this.setHtmlContent(htmlContentConfiguration.getApofinderV1Html());
        break;
      case "apofinder-v2":
        this.setCodeJar(snippetConfiguration.getApofinderV2(this.widgetSnippetsUrls[this.formData.environment]));
        this.setScriptContent(scriptContentConfiguration.getApofinderV2Script(this.widgetSnippetsUrls[this.formData.environment].widgetBase));
        this.setHtmlContent(htmlContentConfiguration.getApofinderV2Html());
        break;
      case "apofinder-v2-for-manufacturer-landing-page":
        this.setCodeJar(snippetConfiguration.getApofinderV2ForMAnufacturerLandingPage(this.widgetSnippetsUrls[this.formData.environment]));
        this.setScriptContent(scriptContentConfiguration.getApofinderV2Script(this.widgetSnippetsUrls[this.formData.environment].widgetBase));
        this.setHtmlContent(htmlContentConfiguration.getApofinderV2Html());
        break;
      case "manufacturer":
        this.setCodeJar(snippetConfiguration.getManufacturer(this.widgetSnippetsUrls[this.formData.environment]));
        this.setScriptContent(scriptContentConfiguration.getManufacturerScript(this.widgetSnippetsUrls[this.formData.environment].widgetBase));
        this.setHtmlContent(htmlContentConfiguration.getManufacturerHtml());
        break;
      case "manufacturer-shop-v2":
        this.setCodeJar(snippetConfiguration.getManufacturerShopV2(this.widgetSnippetsUrls[this.formData.environment]));
        this.setScriptContent(scriptContentConfiguration.getManufacturerShopScript(this.widgetSnippetsUrls[this.formData.environment].widgetBase));
        this.setHtmlContent(htmlContentConfiguration.getManufacturerShopHtml());
        break;
      case "manufacturer-shop-v2-deep-link-default":
        this.setCodeJar(snippetConfiguration.getManufacturerShopV2DeepLinkDefault(this.widgetSnippetsUrls[this.formData.environment]));
        this.setScriptContent(scriptContentConfiguration.getManufacturerShopScript(this.widgetSnippetsUrls[this.formData.environment].widgetBase));
        this.setHtmlContent(htmlContentConfiguration.getManufacturerShopHtml());
        break;
      case "manufacturer-shop-v2-deep-link-custom":
        this.setCodeJar(snippetConfiguration.getManufacturerShopV2DeepLinkCustom(this.widgetSnippetsUrls[this.formData.environment]));
        this.setScriptContent(scriptContentConfiguration.getManufacturerShopScript(this.widgetSnippetsUrls[this.formData.environment].widgetBase));
        this.setHtmlContent(htmlContentConfiguration.getManufacturerShopHtml());
        break;
      case "net-doctor":
        this.setCodeJar(snippetConfiguration.getNetDoctor(this.widgetSnippetsUrls[this.formData.environment]));
        this.setScriptContent(scriptContentConfiguration.getNetdoctorScript(this.widgetSnippetsUrls[this.formData.environment].widgetBase));
        this.setHtmlContent(htmlContentConfiguration.getNetdoctorHtml());
        break;
      case "net-doctor-indicator":
        this.setCodeJar(snippetConfiguration.getNetDoctorIndicator(this.widgetSnippetsUrls[this.formData.environment]));
        this.setScriptContent(scriptContentConfiguration.getNetdoctorIndicatorScript(this.widgetSnippetsUrls[this.formData.environment].widgetBase));
        this.setHtmlContent(htmlContentConfiguration.getNetdoctorIndicatorHtml());
        break;
      case "net-doctor-with-pharmacy":
        this.setCodeJar(snippetConfiguration.getNetDoctorWithPharmacy(this.widgetSnippetsUrls[this.formData.environment]));
        this.setScriptContent(scriptContentConfiguration.getNetdoctorWithPharmacyScript(this.widgetSnippetsUrls[this.formData.environment].widgetBase));
        this.setHtmlContent(htmlContentConfiguration.getNetdoctorWithPharmacyHtml());
        break;
      case "product-search":
        this.setCodeJar(snippetConfiguration.getProductSearch(this.widgetSnippetsUrls[this.formData.environment]));
        this.setScriptContent(scriptContentConfiguration.getProductSearchScript(this.widgetSnippetsUrls[this.formData.environment].widgetBase));
        this.setHtmlContent(htmlContentConfiguration.getProductSearchHtml());
        break;
      case "product-with-category":
        this.setCodeJar(snippetConfiguration.getProductWithCategory(this.widgetSnippetsUrls[this.formData.environment]));
        this.setScriptContent(scriptContentConfiguration.getProductWithCategoryScript(this.widgetSnippetsUrls[this.formData.environment].widgetBase));
        this.setHtmlContent(htmlContentConfiguration.getProductWithCategoryHtml());
        break;
      case "shopping":
        this.setCodeJar(snippetConfiguration.getShopping(this.widgetSnippetsUrls[this.formData.environment]));
        break;
      case "wirkenstoff":
        this.setCodeJar(snippetConfiguration.getWirkenstoff(this.widgetSnippetsUrls[this.formData.environment]));
        break;
      default:
        this.setCodeJar("");
    }
  }

  setPreview(): void {
    const previewContentElement = document.querySelector(".preview-content");
    if (previewContentElement) {
      previewContentElement.innerHTML = "";

      const iframeElement = document.createElement("iframe");
      this.formData.backgroundColor = this.formData.backgroundColor.replace("#", "")
      iframeElement.src = `/preview/${this.formData.type}?formData=${JSON.stringify(this.formData)}&config=${JSON.stringify(this.getCodeJar()).replaceAll("#", "~")}`;
      previewContentElement.appendChild(iframeElement);
    }
  }

  setCodeJar(data: string): void {
    this.codeJar.updateCode(data.length > 0 ? 'var config = ' + data : "");
  }

  setScriptContent(data: string): void {
    this.codeJarForScripts.updateCode(data);
  }

  setHtmlContent(data: string): void {
    this.codeJarForHtml.updateCode(data);
  }

  getCodeJar(): any {
    return JSON.parse(this.codeJar.toString().replace("var config = ", ""));
  }

  resetWidgetList(): void {
    this.formData.widget = "";
  }

  resetCodeJar(): void {
    this.setCodeJar("");
    this.setScriptContent("");
    this.setHtmlContent("");
  }

  copyToClipboard(element: HTMLInputElement): void {
    this.showNotification = false
    clearTimeout(this.notificationTimeout);
    const editorElement = element.closest(".editor-style").querySelector(".editor") as HTMLElement;
    if (element) {
      navigator.clipboard.writeText(editorElement.innerText).then(() => {
        this.showNotification = true;
        this.notificationTimeout = setTimeout(() => {
          this.showNotification = false
        }, 3000)
      });
    }
  }

  mounted(): void {
    const configEditor = document.querySelector(".config-editor") as HTMLElement;
    if (configEditor) {
      this.codeJar = CodeJar(configEditor, Prism.highlightElement, {
        tab: ' '.repeat(2), // default is '\t'
      });
    }
    const scriptEditor = document.querySelector(".script-editor") as HTMLElement;
    if (scriptEditor) {
      this.codeJarForScripts = CodeJar(scriptEditor, Prism.highlightElement, {
        tab: ' '.repeat(2), // default is '\t'
      });
    }
    const htmlEditor = document.querySelector(".html-editor") as HTMLElement;
    if (htmlEditor) {
      this.codeJarForHtml = CodeJar(htmlEditor, Prism.highlightElement, {
        tab: ' '.repeat(2), // default is '\t'
      });
    }
    this.isMacbook = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
    this.isFirefox = /(Firefox)/i.test(navigator.userAgent);
  }
}
