import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router"
import HomeIndex from "../views/Home/Index.vue"
import PreviewIframeIndex from "@/views/Preview/Iframe/Index.vue";
import PreviewSnippetIndex from "@/views/Preview/Snippet/Index.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "HomeIndexVue",
    component: HomeIndex
  },
  {
    path: "/preview/iframe",
    name: "PreviewIframeIndexVue",
    component: PreviewIframeIndex
  },
  {
    path: "/preview/snippet",
    name: "PreviewSnippetIndexVue",
    component: PreviewSnippetIndex
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
