import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "preview" }
const _hoisted_2 = { class: "section-default" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_el_container = _resolveComponent("el-container")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_main = _resolveComponent("el-main")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_container, null, {
          default: _withCtx(() => [
            _createVNode(_component_Navigation)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_main, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_container, null, {
          default: _withCtx(() => [
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "section-slider" }, [
              _createElementVNode("img", {
                src: "https://via.placeholder.com/1366x460",
                alt: ""
              })
            ], -1)),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_el_row, {
                gutter: 20,
                justify: "center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_col, {
                    xs: 24,
                    sm: 24,
                    md: _ctx.formData.previewType === 'article-with-sidebar' ? 16 : 24,
                    lg: _ctx.formData.previewType === 'article-with-sidebar' ? 16 : 24,
                    xl: _ctx.formData.previewType === 'article-with-sidebar' ? 16 : 24
                  }, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createElementVNode("div", { class: "headline" }, [
                        _createElementVNode("h1", { class: "title" }, " Today is a sunny day ")
                      ], -1),
                      _createElementVNode("div", { class: "summary" }, " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ", -1),
                      _createElementVNode("div", {
                        id: "IframeContainer",
                        class: "iframe-container"
                      }, null, -1),
                      _createElementVNode("div", { class: "summary" }, " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ", -1)
                    ])),
                    _: 1
                  }, 8, ["md", "lg", "xl"]),
                  (_ctx.formData.previewType === 'article-with-sidebar')
                    ? (_openBlock(), _createBlock(_component_el_col, {
                        key: 0,
                        xs: 24,
                        sm: 24,
                        md: 8,
                        lg: 8,
                        xl: 8
                      }, {
                        default: _withCtx(() => _cache[1] || (_cache[1] = [
                          _createElementVNode("div", { class: "articles" }, [
                            _createElementVNode("div", { class: "headline" }, [
                              _createElementVNode("h1", { class: "title" }, " Latest Articles ")
                            ]),
                            _createElementVNode("ul", null, [
                              _createElementVNode("li", null, [
                                _createElementVNode("img", {
                                  src: "https://via.placeholder.com/150",
                                  alt: ""
                                }),
                                _createElementVNode("a", { href: "#" }, "World Cup qualifiers reaction & Premier League build-up")
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("img", {
                                  src: "https://via.placeholder.com/150",
                                  alt: ""
                                }),
                                _createElementVNode("a", { href: "#" }, "Novak Djokovic will face Alexander Zverev in semi-final")
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("img", {
                                  src: "https://via.placeholder.com/150",
                                  alt: ""
                                }),
                                _createElementVNode("a", { href: "#" }, "Raducanu 'didn't expect to be here'")
                              ]),
                              _createElementVNode("li", null, [
                                _createElementVNode("img", {
                                  src: "https://via.placeholder.com/150",
                                  alt: ""
                                }),
                                _createElementVNode("a", { href: "#" }, "Usain Bolt rules out athletics comeback but has \"got that itch\" for a return")
                              ])
                            ])
                          ], -1)
                        ])),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "script-content" }, null, -1))
  ]))
}