const getApofinderV1 = (environment: { widgetBase: string, apiBase: string, apiBaseElastic: string }): string => {
  return JSON.stringify({
    "base": {
      "widgetBase": environment.widgetBase,
      "apiBase": environment.apiBase,
      "apiBaseElastic": environment.apiBaseElastic,
      "couponCode": "CPN-1203",
      "headline": "",
      "text": "",
      "view": "map",
      "openLinksInNewWindow": true,
      "hideMagazines": true,
      "pharmaciesPerPage": 5
    },
    "parameters": {
      "query": "Koln",
      "lat": 50.9299772,
      "lng": 7.3352415,
      "geolocation": false,
      "open": true,
      "delivery": true,
      "magazines": "37,22",
      "pzn": "14060537",
      "test": false,
      "pcase": 1,
      "products": "01578675,14060537",
      "productCategory": "",
      "wirkstoffe": "",
      "showAllPharmacies": false,
      "showAllPharmaciesFilter": false,
    },
    "utm": {
      "source": "",
      "medium": "shopping_widget_apofinder",
      "campaign": "",
      "term": "",
      "content": ""
    },
    "settings": {
      "customLabel": "",
      "customUrl": "",
      "locationMarkerIcon": `${environment.widgetBase}/widget/build/images/loreal-marker.svg`,
      "pharmacyMarkerIcon": `${environment.widgetBase}/widget/build/images/loreal-marker.svg`,
      "primaryFont": "Josefin+Sans:wght@400;500;600;700",
      "primaryFontFamily": `"Josefin Sans", sans-serif`,
      "secondaryFont": "",
      "secondaryFontFamily": "",
      "primaryColor": "#00acc2",
      "secondaryColor": "#333333",
      "pharmacyOpenColor": "#333333",
      "pharmacyClosedColor": "#333333",
      "searchButtonColor": "#ff6478",
      "pharmacyButtonColor": "#333333",
      "filterButtonsRadius": "0",
      "pharmacyButtonRadius": "20px"
    },
    "map": {
      "styles": [
        {
          "featureType": "all",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "on"
            },
            {
              "saturation": "-100"
            }
          ]
        },
        {
          "featureType": "administrative",
          "elementType": "labels.text",
          "stylers": [
            {
              "color": "#000000"
            }
          ]
        },
        {
          "featureType": "landscape",
          "elementType": "labels.text",
          "stylers": [
            {
              "color": "#000000"
            }
          ]
        },
        {
          "featureType": "landscape.natural",
          "elementType": "labels.text",
          "stylers": [
            {
              "color": "#000000"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text",
          "stylers": [
            {
              "color": "#000000"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "saturation": "0"
            },
            {
              "lightness": "-9"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels",
          "stylers": [
            {
              "color": "#0b0a0a"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text",
          "stylers": [
            {
              "color": "#000000"
            },
            {
              "lightness": "29"
            }
          ]
        }
      ]
    }
  }, null, 2)
};

const getApofinderV2 = (environment: { widgetBase: string, apiBase: string, apiBaseElastic: string }): string => {
  return JSON.stringify({
    "base": {
      "widgetBase": environment.widgetBase,
      "apiBase": environment.apiBase,
      "apiBaseElastic": environment.apiBaseElastic
    },
    "parameters": {
      "couponCode": "CPN-1203",
      "headline": "",
      "text": "",
      "view": "list",
      "openLinksInNewWindow": true,
      "hideMagazines": true,
      "pharmaciesPerPage": 5,
      "query": "Koln",
      "lat": 50.9299772,
      "lng": 7.3352415,
      "geolocation": false,
      "open": true,
      "delivery": true,
      "expressDelivery": false,
      "magazines": "37,22",
      "pzn": "14060537",
      "test": false,
      "pcase": 1,
      "products": "01578675,14060537",
      "productCategory": "",
      "wirkstoffe": "",
      "showAllPharmacies": false,
      "showAllPharmaciesFilter": false,
      "needsGoogleConsent": true,
      "consentActive": true,
      "showGoogleMap": true,
      "activateAllPharmaciesFilter": false,
      "manufacturer": "",
      "destination": "",
      "customUrl": "",
      "recommendedProducts": "",
      "showRequestButton": false,
      "googleMapsLegalText": ""
    },
    "filtersToHide": {
      "openPharmacies": false,
      "delivery": false,
      "expressDelivery": true,
      "magazines": true
    },
    "utm": {
      "source": "",
      "medium": "shopping_widget_apofinder",
      "campaign": "",
      "term": "",
      "content": ""
    },
    "settings": {
      "locationMarkerIcon": `${environment.widgetBase}/widget/build/images/location_here.svg`,
      "pharmacyMarkerIcon": `${environment.widgetBase}/widget/build/images/IA_pin2.png`,
      "primaryFont": "Roboto+Flex:wght@300;400;500;600;700",
      "primaryFontFamily": "'Roboto Flex', sans-serif",
      "primaryColor": "#131210",
      "secondaryColor": "#353535",
      "pharmacyOpenColor": "#248232",
      "pharmacyClosedColor": "#FF0000",
      "searchButtonColor": "#FF0000",
      "pharmacyButtonColor": "#131210",
      "filterButtonsRadius": "4px",
      "pharmacyButtonRadius": "8px",
      "buttonTextColor": "#fff"
    },
    "map": {
      "styles": [
        {
          "featureType": "all",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "on"
            },
            {
              "saturation": "-100"
            }
          ]
        },
        {
          "featureType": "administrative",
          "elementType": "labels.text",
          "stylers": [
            {
              "color": "#000000"
            }
          ]
        },
        {
          "featureType": "landscape",
          "elementType": "labels.text",
          "stylers": [
            {
              "color": "#000000"
            }
          ]
        },
        {
          "featureType": "landscape.natural",
          "elementType": "labels.text",
          "stylers": [
            {
              "color": "#000000"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text",
          "stylers": [
            {
              "color": "#000000"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "saturation": "0"
            },
            {
              "lightness": "-9"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels",
          "stylers": [
            {
              "color": "#0b0a0a"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text",
          "stylers": [
            {
              "color": "#000000"
            },
            {
              "lightness": "29"
            }
          ]
        }
      ]
    }
  }, null, 2)
};

const getApofinderV2ForMAnufacturerLandingPage = (environment: { widgetBase: string, apiBase: string, apiBaseElastic: string }): string => {
  return JSON.stringify({
    "base": {
      "widgetBase": environment.widgetBase,
      "apiBase": environment.apiBase,
      "apiBaseElastic": environment.apiBaseElastic
    },
    "parameters": {
      "couponCode": "CPN-1203",
      "headline": "",
      "text": "",
      "view": "list",
      "openLinksInNewWindow": true,
      "hideMagazines": true,
      "pharmaciesPerPage": 5,
      "query": "",
      "lat": 50.9299772,
      "lng": 7.3352415,
      "geolocation": false,
      "open": false,
      "delivery": false,
      "expressDelivery": false,
      "magazines": "",
      "pzn": "14060537",
      "test": false,
      "pcase": 1,
      "products": "01578675,14060537",
      "productCategory": "",
      "wirkstoffe": "",
      "showAllPharmacies": true,
      "showAllPharmaciesFilter": true,
      "needsGoogleConsent": true,
      "consentActive": true,
      "showGoogleMap": true,
      "activateAllPharmaciesFilter": false,
      "manufacturer": "vividrin",
      "destination": "",
      "customUrl": "",
      "recommendedProducts": "",
      "showRequestButton": false,
      "googleMapsLegalText": "Mit Klick unten auf die Schaltfläche „Karte laden“ stimmen Sie zu, dass Ihre personenbezogenen Daten (IP-Adresse) zur Anzeige der Suchergebnisse in einer Karte mithilfe von Google Maps an die Google Ireland Limited übermittelt werden. Weitere Informationen finden Sie in den Datenschutzhinweisen (siehe Seitenende)."
    },
    "filtersToHide": {
      "openPharmacies": false,
      "delivery": false,
      "expressDelivery": true,
      "magazines": true
    },
    "utm": {
      "source": "",
      "medium": "shopping_widget_apofinder",
      "campaign": "",
      "term": "",
      "content": ""
    },
    "settings": {
      "locationMarkerIcon": `${environment.widgetBase}/widget/build/images/location_here.svg`,
      "pharmacyMarkerIcon": `${environment.widgetBase}/widget/build/images/IA_pin2.png`,
      "primaryFont": "Roboto+Flex:wght@300;400;500;600;700",
      "primaryFontFamily": "'Roboto Flex', sans-serif",
      "primaryColor": "#131210",
      "secondaryColor": "#353535",
      "pharmacyOpenColor": "#248232",
      "pharmacyClosedColor": "#FF0000",
      "searchButtonColor": "#FF0000",
      "pharmacyButtonColor": "#131210",
      "filterButtonsRadius": "4px",
      "buttonTextColor": "#fff",
      "pharmacyButtonRadius": "8px"
    },
    "map": {
      "styles": [
        {
          "featureType": "all",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "on"
            },
            {
              "saturation": "-100"
            }
          ]
        },
        {
          "featureType": "administrative",
          "elementType": "labels.text",
          "stylers": [
            {
              "color": "#000000"
            }
          ]
        },
        {
          "featureType": "landscape",
          "elementType": "labels.text",
          "stylers": [
            {
              "color": "#000000"
            }
          ]
        },
        {
          "featureType": "landscape.natural",
          "elementType": "labels.text",
          "stylers": [
            {
              "color": "#000000"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text",
          "stylers": [
            {
              "color": "#000000"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "saturation": "0"
            },
            {
              "lightness": "-9"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels",
          "stylers": [
            {
              "color": "#0b0a0a"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text",
          "stylers": [
            {
              "color": "#000000"
            },
            {
              "lightness": "29"
            }
          ]
        }
      ]
    }
  }, null, 2)
};

const getManufacturer = (environment: { widgetBase: string, apiBase: string }): string => {
  return JSON.stringify({
    "base": {
      "language": "de",
      "widgetBase": environment.widgetBase,
      "apiBase": environment.apiBase
    },
    "utm": {
      "medium": "shopping_widget_popup"
    },
    "settings": {
      "widgetName": "",
      "type": 1,
      "products": "234234,234234,23423",
      "consentActive": true,
    }
  }, null, 2)
};

const getManufacturerShopV2 = (environment: { widgetBase: string, apiBase: string }): string => {
  return JSON.stringify({
    "base": {
      "language": "de",
      "widgetBase": environment.widgetBase,
      "apiBase": environment.apiBase
    },
    "utm": {
      "medium": "shopping_widget_popup"
    },
    "settings": {
      "style": {
        "button": {
          "borderRadius": "4px",
          "background": "#FF0000",
          "backgroundHover": "#D10000",
          "color": "#ffffff",
          "fontSize": "12px",
          "fontWeight": 700,
          "textTransform": "uppercase",
          "minWidth": "175px"
        },
        "modal": {
          "zIndex": 1050,
          "brandColor": "#009ACE",
          "backdrop": {
            "opacity": 0.5,
            "backgroundColor": "#0e4095"
          },
          "header": {
            "close": {
              "color": "#000000",
              "fontSize": "27px",
              "fontWeight": 400
            }
          },
          "body": {
            "title": {
              "color": "#000000",
              "fontSize": "22px",
              "fontWeight": 700,
              "textAlign": "left"
            },
            "summary": {
              "fontSize": "16px",
              "fontWeight": 400,
              "color": "#353535",
              "textAlign": "left"
            },
            "form": {
              "action": {
                "textAlign": "center"
              }
            },
            "description": {
              "fontSize": "12px",
              "fontWeight": 500,
              "color": "#000000",
              "textAlign": "center"
            },
            "instruction": {
              "fontSize": "12px",
              "fontWeight": 400,
              "color": "#7B7B7B",
              "img": {
                "width": "80px",
                "marginLeft": "8px"
              }
            }
          }
        }
      },
      "content": {
        "showAd": false,
        "mandatoryInfo": "",
        "button": {
          "text": "Apotheke suchen"
        },
        "modal": {
          "title": "So einfach geht’s",
          "summary": "Apotheke in Ihrer Nähe suchen",
          "button": "Apotheke suchen"
        }
      },
      "typography": {
        "primaryFont": "Roboto+Flex:wght@300;400;600;700",
        "primaryFontFamily": "'Roboto Flex', sans-serif",
        "secondaryFont": "IBM+Plex+Serif:wght@400;700",
        "secondaryFontFamily": "'IBM Plex Serif', sans-serif"
      },
      "type": 1,
      "productDetail": false,
      "pzn": "04024782",
      "pcase": "",
      "manufacturer": "vividrin",
      "couponCode": "test",
      "recommendedProducts": "13655004,10832664,14287904,02830579",
      "deepLink": false,
      "deepLinkType": "default",
      "consentActive": true,
      "showGoogleMap": true,
    }
  }, null, 2)
};

const getManufacturerShopV2DeepLinkDefault = (environment: { widgetBase: string, apiBase: string }): string => {
  return JSON.stringify({
    "base": {
      "language": "de",
      "widgetBase": environment.widgetBase,
      "apiBase": environment.apiBase
    },
    "utm": {
      "medium": "shopping_deeplink_produkt"
    },
    "settings": {
      "style": {
        "button": {
          "borderRadius": "4px",
          "background": "#FF0000",
          "backgroundHover": "#D10000",
          "color": "#ffffff",
          "fontSize": "12px",
          "fontWeight": 700,
          "textTransform": "uppercase",
          "minWidth": "175px"
        },
        "modal": {
          "zIndex": 1050,
          "brandColor": "#009ACE",
          "backdrop": {
            "opacity": 0.5,
            "backgroundColor": "#0e4095"
          },
          "header": {
            "close": {
              "color": "#000000",
              "fontSize": "27px",
              "fontWeight": 400
            }
          },
          "body": {
            "title": {
              "color": "#000000",
              "fontSize": "22px",
              "fontWeight": 700,
              "textAlign": "left"
            },
            "summary": {
              "fontSize": "16px",
              "fontWeight": 500,
              "color": "#353535",
              "textAlign": "left"
            },
            "form": {
              "action": {
                "textAlign": "center"
              }
            },
            "description": {
              "fontSize": "12px",
              "fontWeight": 500,
              "color": "#000000",
              "textAlign": "center"
            },
            "instruction": {
              "fontSize": "12px",
              "fontWeight": 400,
              "color": "#7B7B7B",
              "img": {
                "width": "80px",
                "marginLeft": "8px"
              }
            }
          }
        }
      },
      "content": {
        "button": {
          "text": "Apotheke suchen"
        },
        "modal": {
          "title": "So einfach geht’s",
          "summary": "Apotheke in Ihrer Nähe suchen",
          "button": "Apotheke suchen"
        }
      },
      "typography": {
        "primaryFont": "Source+Sans+Pro:wght@300;400;600;700",
        "primaryFontFamily": "'Source Sans Pro', sans-serif",
        "secondaryFont": "Ubuntu:wght@400;700",
        "secondaryFontFamily": "'Ubuntu', sans-serif"
      },
      "type": 1,
      "productDetail": false,
      "pzn": "04024782",
      "pcase": "",
      "manufacturer": "vividrin",
      "couponCode": "test",
      "recommendedProducts": "13655004,10832664,14287904,02830579",
      "deepLink": true,
      "deepLinkType": "default",
      "consentActive": true,
      "showGoogleMap": true,
    }
  }, null, 2)
};

const getNetDoctorWithPharmacy = (environment: { widgetBase: string, apiBase: string }): string => {
  return JSON.stringify({
    "base": {
      "language": "de",
      "widgetBase": environment.widgetBase,
      "apiBase": environment.apiBase
    },
    "utm": {
      "source": "",
      "medium": "netdoktor_with_pharmacy_widget",
      "campaign": "",
      "term": "",
      "content": ""
    },
    "settings": {
      "recommendedProducts": "00150320,11517203,01939446",
      "channel": 6,
      "pcase": "",
      "productDetail": false,
      "couponCode": "test",
      "deepLink": false,
      "deepLinkType": "default",
      "consentActive": true,
      "showGoogleMap": true,
      "style": {
        "primaryColor": "#EBFAFF",
        "secondaryColor": "#009ACE",
        "brandColor": "#009ACE",
        "buttonColor": "#131210",
      },
      "typography": {
        "primaryFont": "Roboto",
        "primaryFontFamily": "'Roboto', sans-serif",
        "secondaryFont": "Ubuntu",
        "secondaryFontFamily": "'Ubuntu', sans-serif"
      },
      "indicator": {
        "headline": "Hilfe aus Ihrer Lieblingsapotheke",
        "subline": "Welches Symptom belastet Sie?",
        "tileAmount": 3,
        "displayProducts": true,
        "symptoms": [
          {
            "image": "",
            "title": "Schmerzhafte Lidrandschwellung",
            "subline": "test subline",
            "link": "/themenuebersicht",
            "pzn": ["11517203"]
          },
          {
            "image": "",
            "title": "Wiederkehrende Lidrandentzündung",
            "subline": "",
            "link": "/themenuebersicht",
            "pzn": ["00150320", "11517203", "01939446"]
          },
          {
            "image": "",
            "title": "Fremdkörpergefühl",
            "subline": "",
            "link": "/themenuebersicht",
            "pzn": ["00068966", "00150320", "01939446"]
          }
        ],
      },
      "manufacturer": {
        "headline": "So einfach geht’s",
        "subline": "Apotheke in Ihrer Nähe suchen und Medikamente online bestellen",
        "submit": "Apotheke suchen"
      }
    }
  }, null, 2)
};

const getManufacturerShopV2DeepLinkCustom = (environment: { widgetBase: string, apiBase: string }): string => {
  return JSON.stringify({
    "base": {
      "language": "de",
      "widgetBase": environment.widgetBase,
      "apiBase": environment.apiBase
    },
    "utm": {
      "medium": "shopping_deeplink_apofinder"
    },
    "settings": {
      "style": {
        "button": {
          "borderRadius": "4px",
          "background": "#32788F",
          "backgroundHover": "#003f54",
          "color": "#ffffff",
          "fontSize": "12px",
          "fontWeight": 700,
          "textTransform": "uppercase",
          "minWidth": "175px"
        },
        "modal": {
          "zIndex": 1050,
          "brandColor": "#009ACE",
          "backdrop": {
            "opacity": 0.5,
            "backgroundColor": "#0e4095"
          },
          "header": {
            "close": {
              "color": "#000000",
              "fontSize": "27px",
              "fontWeight": 400
            }
          },
          "body": {
            "title": {
              "color": "#000000",
              "fontSize": "22px",
              "fontWeight": 700,
              "textAlign": "left"
            },
            "summary": {
              "fontSize": "16px",
              "fontWeight": 400,
              "color": "#353535",
              "textAlign": "left"
            },
            "form": {
              "action": {
                "textAlign": "center"
              }
            },
            "description": {
              "fontSize": "12px",
              "fontWeight": 500,
              "color": "#000000",
              "textAlign": "center"
            },
            "instruction": {
              "fontSize": "12px",
              "fontWeight": 400,
              "color": "#7B7B7B",
              "img": {
                "width": "80px",
                "marginLeft": "8px"
              }
            }
          }
        }
      },
      "content": {
        "button": {
          "text": "Apotheke suchen"
        },
        "modal": {
          "title": "So einfach geht’s",
          "summary": "Apotheke in Ihrer Nähe suchen",
          "button": "Apotheke suchen"
        }
      },
      "typography": {
        "primaryFont": "Source+Sans+Pro:wght@300;400;600;700",
        "primaryFontFamily": "'Source Sans Pro', sans-serif",
        "secondaryFont": "Ubuntu:wght@400;700",
        "secondaryFontFamily": "'Ubuntu', sans-serif"
      },
      "type": 1,
      "productDetail": false,
      "pzn": "04024782",
      "pcase": "",
      "manufacturer": "linola",
      "couponCode": "test",
      "recommendedProducts": "13655004,10832664,14287904,02830579",
      "deepLink": true,
      "deepLinkType": "custom",
      "consentActive": true,
      "showGoogleMap": true,
    }
  }, null, 2)
};

const getNetDoctor = (environment: { widgetBase: string, apiBase: string }): string => {
  return JSON.stringify({
    "base": {
      "language": "de",
      "widgetBase": environment.widgetBase,
      "apiBase": environment.apiBase
    },
    "utm": {
      "medium": "widget"
    },
    "settings": {
      "style": 1,
      "wirkstoffe": "TEST",
      "widgetName": "",
      "backgroundImageUrl": `${environment.widgetBase}/widget/build/images/poz-1.png`
    }
  }, null, 2)
};

const getNetDoctorIndicator = (environment: { widgetBase: string, apiBase: string }): string => {
  return JSON.stringify({
    "base": {
      "language": "de",
      "widgetBase": environment.widgetBase,
      "apiBase": environment.apiBase
    },
    "utm": {
      "medium": 'netdoktor_indication_widget'
    },
    "settings": {
      "style": {},
      "typography": {
        "primaryFont": "Roboto",
        "primaryFontFamily": "'Roboto', sans-serif",
        "secondaryFont": "Ubuntu",
        "secondaryFontFamily": "'Ubuntu', sans-serif"
      },
      "headline": 'Hilfe aus Ihrer Lieblingsapotheke',
      "subtitle": 'Welches Symptom belastet Sie?',
      "tileAmount": 3,
      "symptoms": [
        {
          "image": '',
          "title": 'Schmerzhafte Lidrandschwellung',
          "subline": '',
          "link": 'https://ihreapotheken.de/produkte'
        },
        {
          "image": '',
          "title": 'Wiederkehrende Lidrandentzündung',
          "subline": '',
          "link": 'https://ihreapotheken.de/produkte'
        },
        {
          "image": '',
          "title": 'Halsschmerzen',
          "subline": 'Schluckbeschwerden und Schmerzen',
          "link": 'https://ihreapotheken.de/produkte'
        }
      ]
    }
  }, null, 2)
};

const getProductSearch = (environment: { widgetBase: string, apiBase: string }): string => {
  return JSON.stringify({
    "base": {
      "widgetBase": environment.widgetBase,
      "apiBase": environment.apiBase
    },
    "settings": {
      "pharmacyID": 2163,
      "primaryColor": "#cc2828"
    },
    "utm": {
      "source": "",
      "medium": "widget",
      "campaign": "",
      "term": "",
      "content": ""
    }
  }, null, 2)
};

const getProductWithCategory = (environment: { widgetBase: string, apiBase: string }): string => {
  return JSON.stringify({
    "base": {
      "widgetBase": environment.widgetBase,
      "apiBase": environment.apiBase
    },
    "settings": {
      "pharmacyID": 2163,
      "pharmacyUrl": "/apotheke/apothekende-testapotheke-für-reservierung-und-app-keine-stadt-12345-2163",
      "pharmacyProductsUrl": "/apothekende-testapotheke-für-reservierung-und-app2-musterstadt-12345-2163",
      "brandPrimary": "#003F54",
      "primary": "#32788F",
      "secondary": "#353535",
      "light": "#F9FAFD",
      "bodyText": "#5c5c5c",
      "action": "#DE1E36"
    },
    "utm": {
      "source": "",
      "medium": "widget",
      "campaign": "",
      "term": "",
      "content": ""
    }
  }, null, 2)
};

const getShopping = (environment: { widgetBase: string, apiBase: string }): string => {
  return JSON.stringify({
    "base": {
      "widgetBase": environment.widgetBase,
      "apiBase": environment.apiBase
    },
    "utm": {
      "source": "",
      "medium": "shopping_widget_popup",
      "campaign": "",
      "term": "",
      "content": ""
    },
    "settings": {
      "format": "300x600",
      "reduced": false,
      "headline": "Hausapotheke: Das gehört hinein - Jetzt auf ia.de bei Ihrer lokalen Apotheke bestellen",
      "buttonTitle": "Jetzt bestellen",
      "importantNotice": "",
      "pzns": "10789112,12550409,14384903"
    }
  }, null, 2)
};

const getWirkenstoff = (environment: { widgetBase: string, apiBase: string }): string => {
  return JSON.stringify({
    "base": {
      "language": "de",
      "widgetBase": environment.widgetBase,
      "apiBase": environment.apiBase
    },
    "utm": {
      "medium": "widget"
    },
    "settings": {
      "wirkstoffe": "TEST",
      "widgetName": ""
    }
  }, null, 2);
};

export {
  getApofinderV1,
  getApofinderV2,
  getApofinderV2ForMAnufacturerLandingPage,
  getManufacturer,
  getManufacturerShopV2,
  getManufacturerShopV2DeepLinkDefault,
  getManufacturerShopV2DeepLinkCustom,
  getNetDoctor,
  getProductSearch,
  getProductWithCategory,
  getShopping,
  getWirkenstoff,
  getNetDoctorIndicator,
  getNetDoctorWithPharmacy
}
