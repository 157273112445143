const getApofinderV1Script = (widgetBase:string) : string => {
  return `(function (win, doc, tag, url, apoFunc, scriptElem) {
    win[apoFunc] = function (elementId, config, callback = null) {
      win.IhreApothekenApoFinderWidget = {
        config: config,
        elementId: elementId,
        apoFunc: apoFunc,
        callback: callback
      }
      scriptElem = doc.createElement(tag);
      scriptElem.async = 1;
      scriptElem.src = url;
      doc.body.appendChild(scriptElem);
    }
  })(window, document, "script", "${widgetBase}/widget/build/apofinder/snippet.min.js", "initializeApofinderV1Widget");

  initializeApofinderV1Widget("Apofinder", config);`
}

const getApofinderV2Script = (widgetBase:string) : string => {
  return `(function (win, doc, tag, url, widgetFuncName, scriptElem, widgetPreinit, widgetObjName) {
    widgetObjName = "ApoFinderWidget";
    widgetPreinit = widgetFuncName + "PreInit";
    win[widgetPreinit] = win[widgetPreinit] || [];
    win[widgetFuncName] = function (elementId, config, callback) {
      if (win[widgetObjName] == null) {
        win[widgetPreinit].push(function () {
          new win[widgetObjName](elementId, config, callback);
        })
      } else {
        new win[widgetObjName](elementId, config, callback);
      }
    }
    scriptElem = doc.createElement(tag);
    scriptElem.async = 1;
    scriptElem.src = url;
    scriptElem.onload = function () {
      for (let i = 0; i < win[widgetPreinit].length; i++) {
        win[widgetPreinit][i]()
      }
      delete win[widgetPreinit];
    }
    doc.body.appendChild(scriptElem);
  })(window, document, "script", "${widgetBase}/widget/build/apofinder-v2/snippet.min.js", "initializeApofinderV2Widget");

  initializeApofinderV2Widget("ia-apofinder-widget", config);`
}

const getManufacturerScript = (widgetBase:string) : string => {
  return `(function (win, doc, tag, url, widgetFuncName, scriptElem, widgetPreinit) {
    widgetPreinit = widgetFuncName + "PreInit";
    win[widgetPreinit] = win[widgetPreinit] || [];
    win[widgetFuncName] = function (elementId, config, callback) {
      if (win.ManufacturerWidget == null) {
        win[widgetPreinit].push(function () {
          new win.ManufacturerWidget(elementId, config, callback);
        })
      } else {
        new win.ManufacturerWidget(elementId, config, callback);
      }
    }
    scriptElem = doc.createElement(tag);
    scriptElem.async = 1;
    scriptElem.src = url;
    scriptElem.onload = function () {
      for (let i = 0; i < win[widgetPreinit].length; i++) {
        win[widgetPreinit][i]()
      }
      delete win[widgetPreinit];
    }
    doc.body.appendChild(scriptElem);
  })(window, document, "script", "${widgetBase}/widget/build/manufacturer-widget/snippet.min.js", "initializeManufacturerWidget");

  initializeManufacturerWidget("ManufacturerWidget", config);`
}

const getManufacturerShopScript = (widgetBase:string) : string => {
  return `(function (win, doc, tag, url, widgetFuncName, scriptElem, widgetPreinit) {
    widgetPreinit = widgetFuncName + "PreInit";
    win[widgetPreinit] = win[widgetPreinit] || [];
    win[widgetFuncName] = function (elementId, config, callback) {
      if (win.ManufacturerShopWidget == null) {
        win[widgetPreinit].push(function () {
          new win.ManufacturerShopWidget(elementId, config, callback);
        })
      } else {
        new win.ManufacturerShopWidget(elementId, config, callback);
      }
    }
    scriptElem = doc.createElement(tag);
    scriptElem.async = 1;
    scriptElem.src = url;
    scriptElem.onload = function () {
      for (let i = 0; i < win[widgetPreinit].length; i++) {
        win[widgetPreinit][i]()
      }
      delete win[widgetPreinit];
    }
    doc.body.appendChild(scriptElem);
  })(window, document, "script", "${widgetBase}/widget/build/manufacturer-shop-widget/snippet.min.js", "initializeManufacturerShopWidget");

  initializeManufacturerShopWidget("ManufacturerShopWidget", config);`
}

const getNetdoctorScript = (widgetBase:string) : string => {
  return `(function (win, doc, tag, url, widgetFuncName, scriptElem, widgetPreinit, widgetObjName) {
    widgetObjName = "NetDoctorWidget";
    widgetPreinit = widgetFuncName + "PreInit";
    win[widgetPreinit] = win[widgetPreinit] || [];
    win[widgetFuncName] = function (elementId, config, callback) {
      if (win[widgetObjName] == null) {
        win[widgetPreinit].push(function () {
          new win[widgetObjName](elementId, config, callback);
        })
      } else {
        new win[widgetObjName](elementId, config, callback);
      }
    }
    scriptElem = doc.createElement(tag);
    scriptElem.async = 1;
    scriptElem.src = url;
    scriptElem.onload = function () {
      for (let i = 0; i < win[widgetPreinit].length; i++) {
        win[widgetPreinit][i]()
      }
      delete win[widgetPreinit];
    }
    doc.body.appendChild(scriptElem);
  })(window, document, "script", "${widgetBase}/widget/build/net-doctor-widget/snippet.min.js", "initializeNetDoctorWidget");

  initializeNetDoctorWidget("NetDoctor", config);`
}

const getNetdoctorIndicatorScript = (widgetBase:string) : string => {
  return `(function (win, doc, tag, url, widgetFuncName, scriptElem, widgetPreinit, widgetObjName) {
      widgetObjName = "NetDoctorIndicatorWidget";
      widgetPreinit = widgetFuncName + "PreInit";
      win[widgetPreinit] = win[widgetPreinit] || [];
      win[widgetFuncName] = function (elementId, config, callback) {
        if (win[widgetObjName] == null) {
          win[widgetPreinit].push(function () {
            new win[widgetObjName](elementId, config, callback);
          })
        } else {
          console.log(win[widgetPreinit])
          new win[widgetObjName](elementId, config, callback);
        }
      }
      scriptElem = doc.createElement(tag);
      scriptElem.async = 1;
      scriptElem.src = url;
      scriptElem.onload = function () {
        for (let i = 0; i < win[widgetPreinit].length; i++) {
          win[widgetPreinit][i]()
        }
        delete win[widgetPreinit];
      }
      doc.body.appendChild(scriptElem);
    })(window, document, "script", "${widgetBase}/widget/build/net-doctor-indicator-widget/snippet.min.js", "initializeNetDoctorIndicatorWidget");

    initializeNetDoctorIndicatorWidget("NetDoctorIndicator", config);`
}

const getNetdoctorWithPharmacyScript = (widgetBase:string) : string => {
  return `(function (win, doc, tag, url, widgetFuncName, scriptElem, widgetPreinit, widgetObjName) {
      widgetObjName = "NetDoctorWithPharmacyWidget";
      widgetPreinit = widgetFuncName + "PreInit";
      win[widgetPreinit] = win[widgetPreinit] || [];
      win[widgetFuncName] = function (elementId, config, callback) {
        if (win[widgetObjName] == null) {
          win[widgetPreinit].push(function () {
            new win[widgetObjName](elementId, config, callback);
          })
        } else {
          console.log(win[widgetPreinit])
          new win[widgetObjName](elementId, config, callback);
        }
      }
      scriptElem = doc.createElement(tag);
      scriptElem.async = 1;
      scriptElem.src = url;
      scriptElem.onload = function () {
        for (let i = 0; i < win[widgetPreinit].length; i++) {
          win[widgetPreinit][i]()
        }
        delete win[widgetPreinit];
      }
      doc.body.appendChild(scriptElem);
    })(window, document, "script", "${widgetBase}/widget/build/net-doctor-with-pharmacy-widget/snippet.min.js", "initializeNetDoctorWithPharmacyWidget");

    initializeNetDoctorWithPharmacyWidget("NetDoctorWithPharmacy", config);`
}

const getProductSearchScript = (widgetBase:string) : string => {
  return `(function (win, doc, tag, url, widgetFuncName, scriptElem, widgetPreinit, widgetObjName) {
    widgetObjName = "ProductSearchWidget";
    widgetPreinit = widgetFuncName + "PreInit";
    win[widgetPreinit] = win[widgetPreinit] || [];
    win[widgetFuncName] = function (elementId, config, callback) {
      if (win[widgetObjName] == null) {
        win[widgetPreinit].push(function () {
          new win[widgetObjName](elementId, config, callback);
        })
      } else {
        new win[widgetObjName](elementId, config, callback);
      }
    }
    scriptElem = doc.createElement(tag);
    scriptElem.async = 1;
    scriptElem.src = url;
    scriptElem.onload = function () {
      for (let i = 0; i < win[widgetPreinit].length; i++) {
        win[widgetPreinit][i]()
      }
      delete win[widgetPreinit];
    }
    doc.body.appendChild(scriptElem);
  })(window, document, "script", "${widgetBase}/widget/build/product-search/snippet.min.js", "initializeProductSearchWidget");

  initializeProductSearchWidget("ProductSearch", config);`
}

const getProductWithCategoryScript = (widgetBase:string) : string => {
  return `(function (win, doc, tag, url, widgetFuncName, scriptElem, widgetPreinit, widgetObjName) {
    widgetObjName = "ProductWithCategoryWidget";
    widgetPreinit = widgetFuncName + "PreInit";
    win[widgetPreinit] = win[widgetPreinit] || [];
    win[widgetFuncName] = function (elementId, config, callback) {
      if (win[widgetObjName] == null) {
        win[widgetPreinit].push(function () {
          new win[widgetObjName](elementId, config, callback);
        })
      } else {
        new win[widgetObjName](elementId, config, callback);
      }
    }
    scriptElem = doc.createElement(tag);
    scriptElem.async = 1;
    scriptElem.src = url;
    scriptElem.onload = function () {
      for (let i = 0; i < win[widgetPreinit].length; i++) {
        win[widgetPreinit][i]()
      }
      delete win[widgetPreinit];
    }
    doc.body.appendChild(scriptElem);
  })(window, document, "script", "${widgetBase}/widget/build/product-with-category/snippet.min.js", "initializeProductWithCategoryWidget");

  initializeProductWithCategoryWidget("ProductWithCategory", config);`
}

export {
  getApofinderV1Script,
  getApofinderV2Script,
  getManufacturerScript,
  getManufacturerShopScript,
  getNetdoctorScript,
  getNetdoctorIndicatorScript,
  getProductSearchScript,
  getProductWithCategoryScript,
  getNetdoctorWithPharmacyScript
}
